<template>
  <Layout>
    <template #header>
      <div class="app-header">
        <a-page-header class="page-header" @back="() => $router.go(-1)">
          <template slot="backIcon">
            <a-icon type="arrow-left" /> 返回
          </template>
          <template slot="title">
            分类页

            <a-tooltip placement="bottomRight">
              <template slot="title">
                打开代表启用自定义模板，关闭恢复默认分类页
              </template>
              <a-switch
                size="small"
                checked-children="开启"
                un-checked-children="关闭"
                :checked="enable"
                @change="changeEnable"
              />
            </a-tooltip>
          </template>
          <template slot="extra">
            <a-tooltip placement="bottomRight">
              <template slot="title">
                需先发布首页装修，分类页才会生效
              </template>
              <a-button @click="save" type="primary">
                保存
              </a-button>
            </a-tooltip>
          </template>
        </a-page-header>
      </div>
    </template>
    <a-spin :spinning="spinning">
      <Design />
    </a-spin>
  </Layout>
</template>

<script>
import { mapState, mapMutations } from "vuex";

import Layout from "@/layout/index.vue";
import Design from "@/design/index.vue";

const CategorySvc = require("@/service/category");

import loadDataMixin from "@/mixins/loadData";
import loadDiscountMixin from "@/mixins/loadDiscount";

import { upgrade, DEFAULT_CAT_NAV } from "@/utils/toolbar";

export default {
  name: "CategoryDecoration",

  data() {
    return {
      spinning: true,
      enable: false,

      isFirstEmpty: false // 是否初次使用，即无装修数据状态
    };
  },
  computed: {
    ...mapState({
      desginData: state => state.design,
      oldDesignData: state => state.oldDesignData
    })
  },

  mixins: [loadDataMixin, loadDiscountMixin],

  methods: {
    ...mapMutations(["setDesignData", "updateOldDesignData"]),

    async save() {
      if (this.isDataEmpty()) {
        this.$message.info("请先添加组件再启用");
        return;
      }

      try {
        const designData = this.$store.state.design;
        const { list, fix } = designData;
        const res = JSON.stringify({ list, fix });
        await CategorySvc.save(res);

        this.$message.info("保存完成");
        this.isFirstEmpty = false;

        this.updateOldDesignData(designData);

        // 保存即启用
        if (!this.enable) {
          this.changeEnable(true);
        }
      } catch (err) {
        console.error(err);
        this.$message.error("保存失败，错误：" + err.message);
      }
    },

    async changeEnable(enable) {
      if (enable && this.isDataEmpty()) {
        this.$message.info("请先添加组件再启用");
        return;
      }

      try {
        // 如果为初次使用，则自动提交保存
        if (this.isFirstEmpty) {
          await this.save();
          return;
        }

        await CategorySvc.updateEnable(enable);
        this.enable = enable;

        this.$message.info(enable ? "已启用分类装修" : "已恢复默认分类");
      } catch (err) {
        console.error(err);
        this.$message.error("保存失败，错误：" + err.message);
      }
    },

    // 检查是否有数据，才允许启用
    isDataEmpty() {
      const designData = this.$store.state.design;
      const { list } = designData;
      return list.length <= 0;
    }
  },

  beforeRouteLeave(to, from, next) {
    const { list, fix } = this.desginData;
    let changed = false;
    if (this.oldDesignData) {
      changed = this.oldDesignData !== JSON.stringify({ list, fix });
    } else {
      changed = list.length > 0 || Object.keys(fix).length > 0;
    }

    if (changed) {
      this.$confirm({
        title: "操作提示",
        content: "您有内容未保存，确认要离开吗？",

        onOk: () => {
          next();
        },
        onCancel() {
          next(false);
        }
      });
    } else {
      next();
    }
  },

  async mounted() {
    try {
      this.spinning = true;

      const data = await CategorySvc.loadData();
      this.enable = !!data.enable;

      let res = "";
      if (data.res) {
        res = JSON.parse(data.res);

        this.isFirstEmpty = false;
      } else {
        // 默认填充分类导航
        res = DEFAULT_CAT_NAV;

        // 空数据标识
        this.isFirstEmpty = true;
      }

      // 自动升级数据，兼容旧记录
      upgrade(res);

      this.setDesignData(res);

      // 记录用于判断变更保存提示
      this.updateOldDesignData(res);

      // 监听首页数据保存
      this.$root.$on("home-data-saved", () => {
        const designData = this.$store.state.design;
        this.updateOldDesignData(designData);
      });

      this.spinning = false;
    } catch (err) {
      console.error(err);
      this.$message.error("未正确加载分类数据，如果页面正常请忽略");

      this.setDesignData(null);
      this.updateOldDesignData(null);

      this.spinning = false;
    }
  },

  components: {
    Layout,
    Design
  }
};
</script>

<style lang="scss"></style>
