import request from "./request";

/**
 * 加载分类装修数据
 */
export const loadData = () => request.get("/d/cat");

/**
 * 保存分类数据
 */
export const save = res => request.post("/u/cat", { res });

/**
 * 切换分类是否启用
 */
export const updateEnable = enable => request.post("/u/cat-enable", { enable });
